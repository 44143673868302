import { getToken } from "../columbus-school-root-config";

export const getHeaders = async (url: string, body: any) => {
  let headers = {
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
    Expires: "0",
    Prgma: "no-cache",
    Authorization: `Bearer ${await getToken()}`,
  };
  return headers;
};
