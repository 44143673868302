import { getAuthUser } from "../columbus-school-root-config";
import { getHeaders } from "./http-interceptor-service";

export async function getLoggedInUserData() {
  let _user = getAuthUser();
  const url =
    process.env.Columbus_API + `api/v1/user/${_user.sub}/authorizeUser`;
  return fetch(url, {
    method: "GET",
    headers: await getHeaders(url, null),
  })
    .then(async (result) => {
      if (result.ok) {
        return result.json();
      } else {
        return Promise.reject(result);
      }
    })
    .catch(async (err) => {
      console.log(err);
      // (errorData.url = url), (errorData.loggedInUserName = getUser());
      // errorData.error = err.status + err.statusText;
      // logErrors(errorData);
    });
}
