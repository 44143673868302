import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import mainLayout from "./layouts/columbus-main-layout.html";
import { Auth0Client, User } from "@auth0/auth0-spa-js";
import { getLoggedInUserData } from "./services/columbus-service";
import { UserNavModel } from "./models/user-nav-model";

let userAccount = null;
let loginName = window.sessionStorage.getItem("tempuser");
let user: User = null;
let userInfo: UserNavModel = null;
const auth0Client = new Auth0Client({
  domain: process.env.Auth0_Domain,
  clientId: process.env.Auth0_CLIENT_ID,
  authorizationParams: {
    audience: process.env.Auth0_Audience,
    redirect_uri: process.env.Auth0_REDIRECT_URI,
  },
  useRefreshTokens: true,
  cacheLocation: "localstorage",
});

if (!!!sessionStorage.getItem("IsInteractionInProgress")) {
  if (loginName === null) {
    sessionStorage.setItem("IsInteractionInProgress", "true");
    (async () => await getTokenAtRefreshScreen())();
  } else {
    (async () => await startApplications())();
  }
}
async function getTokenAtRefreshScreen() {
  try {
    await auth0Client.loginWithRedirect().then((val) => {
      (async () => await startApplications())();
    });
  } catch (error) {
    if (error.error !== "login_required") {
      throw error;
    }
  }
}
async function startApplications() {
  if (auth0Client == null) {
    window.sessionStorage.removeItem("tempuser");
  } else {
    userAccount = await auth0Client.getUser();
    window.sessionStorage.setItem("tempuser", "columbus");
  }
  sessionStorage.removeItem("IsInteractionInProgress");
  let routes = constructRoutes(mainLayout);
  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name);
    },
  });
  applications.forEach(registerApplication);
  constructLayoutEngine({ routes, applications }).activate();
  start();

  await completeAuthentication();
}

async function completeAuthentication() {
  const isAuthenticated = await auth0Client.isAuthenticated();

  if (isAuthenticated) {
    window.history.replaceState({}, document.title, window.location.pathname);
    await redirectToDashBoard();
    return;
  }
  const query = window.location.search;
  const shouldParseResult = query.includes("code=") && query.includes("state=");

  if (shouldParseResult) {
    try {
      const result = await auth0Client.handleRedirectCallback();
    } catch (err) {
      console.log("Error parsing redirect:", err);
    }
    window.history.replaceState({}, document.title, "/");
    await redirectToDashBoard();
  }
}
async function redirectToDashBoard() {
  await getUserDetails();
  userInfo = await getLoggedInUserData();
  const origin = `${window.location.origin}`;
  if (window.location.pathname == "/") {
    window.location.href = origin.concat(userInfo.menuDetails[0].menuPath);
  }
}
async function getAccessToken() {
  try {
    const token = await auth0Client.getTokenSilently();
    return token;
  } catch {
    await getTokenAtRefreshScreen();
  }
}
async function getUserDetails() {
  try {
    user = await auth0Client.getUser();
    return user;
  } catch {
    await getTokenAtRefreshScreen();
  }
}
async function LogOutUser() {
  window.sessionStorage.removeItem("tempuser");
  await auth0Client.logout();
}
export const getToken = async (): Promise<string> =>
  Promise.resolve(await getAccessToken());
export const getUser = async (): Promise<any> =>
  Promise.resolve(await getUserDetails());
export const getAuthUser = (): User => user;
export const getNavUser = (): any => userInfo;
// export const getNavUser = async (): Promise<any> =>
//   Promise.resolve(await getLoggedInUserData());
export const logOutUser = async (): Promise<any> =>
  Promise.resolve(await LogOutUser());
export { validateUser } from "./services/user-validate";
//export { userRole } from './models/roles';
//System.import('@SchoolMS/styleguide').then(() => {
// Activate the layout engine once the styleguide CSS is loaded
//layoutEngine.activate();
//start();
//});
